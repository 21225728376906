/** @module Constants */

/**
 * Exports a set of systemwide constants.
 *
 * Many of these constants need to be interpolated with some value.
 *
 * @example
 * import {
 *     BACKSTAGE_PACKAGE_DETAILS_PATH,
 *     MARKETPLACE_MAIN_PATH,
 *     MARKETPLACE_DESCRIPTION_PATH
 * } from 'libs/utils/constants';
 *
 * ...
 *
 * const url = MARKETPLACE_MAIN_PATH.replace('{{eventId}}', eventId);
 */

export * from 'libs/utils/icons';

// General

/**
 * Is the app built for AWS deployment
 * @constant {boolean} IS_AWS
 */
export const IS_AWS = BSTG.cloud === 'aws';

/**
 * Valid URL regular expression
 * @constant {RegExp} VALID_URL_REGEX
 */
// eslint-disable-next-line no-irregular-whitespace
export const VALID_URL_REGEX = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;

/**
 * Valid HTTPS URL regular expression
 * @constant {RegExp} VALID_HTTPS_URL_REGEX
 */
export const VALID_HTTPS_URL_REGEX = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

/**
 * Valid e-mail regular expression
 * @constant {RegExp} VALID_EMAIL_REGEX
 */
export const VALID_EMAIL_REGEX = /^.+@.+$/i;

/**
 * Valid HEX color regular expression
 * @constant {RegExp} VALID_HEX_COLOR_REGEX
 */
export const VALID_HEX_COLOR_REGEX = /^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/;

/**
 * Valid time in 00(sec) or 00:00(min:sec) format
 * @constant {RegExp} VALID_TIME_MINSEC
 */
export const VALID_TIME_MINSEC = /^(?:([0-5]?\d):)?([0-5]?\d)$/;

/**
 * URL port regex
 * @constant {RegExp} ADDRESS_PORT_REGEX
 */
export const ADDRESS_PORT_REGEX = /:([0-9]{1,5})/;

/**
 * Event's ID matcher regular expression
 * @constant {{RegExp}} EVENT_ID_REGEX
 */
export const EVENT_ID_REGEX = /[a-z0-9]{32}/;

/**
 * The default root domain suffix.
 * @constant {String} DEFAULT_APP_ROOT_DOMAIN
 */
export const DEFAULT_APP_ROOT_DOMAIN = '.4pax.com';

let appRootDomain, emailDomain, publicDomain;
if ((BSTG.env === 'staging') || (BSTG.env === 'stage') || (BSTG.env === 'preproduction')) {
    appRootDomain = '.spotme-stage.com';
    emailDomain = 'spotme-stage.com';
    publicDomain = 'on.spotme-stage.com';
} else if ((BSTG.env === 'aws-dev')) {
    appRootDomain = '.spotme-dev.com';
    emailDomain = 'spotme-dev.com';
    publicDomain = 'on.spotme-dev.com';
} else if (BSTG.env === 'dev') {
    appRootDomain = '.4pax.com';
    emailDomain = '4pax.com';
    publicDomain = 'on.4pax.com';
} else {
    appRootDomain = '.spotme.com';
    emailDomain = 'spotme.com';
    publicDomain = 'on.spotme.com';
}

/**
 * The production root domain suffix.
 * @constant {String} PRODUCTION_APP_ROOT_DOMAIN
 */
export const PRODUCTION_APP_ROOT_DOMAIN = appRootDomain;

/**
 * Email domain to use when sending emails
 * @constant {String} EMAIL_DOMAIN
 */
export const EMAIL_DOMAIN = emailDomain;

/**
 * Unknown default node name.
 * @constant {String} UNKNOWN_DEFAULT_NODE
 */
export const UNKNOWN_DEFAULT_NODE = 'unknown';

/**
 * The route to the templates view with the create Modal and a prefield event.
 * Interpolation: `{{eventId}}`
 * @constant {String} CREATE_TEMPLATE_FROM_EVENT_ROUTE
 */
export const CREATE_TEMPLATE_FROM_EVENT_ROUTE = 'templates?create&eid={{eventId}}';

/**
 * APIs base endpoint.
 * @constant {String} API_BASE_PATH
 */
export const API_BASE_PATH = '/api/v1';

/**
 * Second version of the APIs base endpoint.
 * @constant {String} API_V2_BASE_PATH
 */
export const API_V2_BASE_PATH = '/api/v2';

/**
 * Application subdomain
 * @constant {String} APP_SUBDOMAIN
 */
export const APP_SUBDOMAIN = 'backstage';

/**
 * Developer application subdomain
 * @constant {String} DEV_APP_SUBDOMAIN
 */
export const DEV_APP_SUBDOMAIN = 'developer';

/**
 * API subdomain
 * @constant {String} API_SUBDOMAIN
 */
export const API_SUBDOMAIN = 'backstage';

/**
 * Studio Express base endpoint.
 * @constant {String} STUDIO_EXPRESS_BASE_PATH
 */
export const STUDIO_EXPRESS_BASE_PATHLET = 'live-session';

/**
 * Studio Express base endpoint.
 * @constant {String} STUDIO_EXPRESS_BASE_PATH
 */
export const STUDIO_EXPRESS_BASE_PATH = `/${STUDIO_EXPRESS_BASE_PATHLET}`;

/**
 * Live display base endpoint.
 * @constant {String} LIVE_DISPLAY_BASE_PATH
 */
export const LIVE_DISPLAY_BASE_PATH = '/live-display';

// Backstage URLs

let backstageDomain = PRODUCTION_APP_ROOT_DOMAIN;
if (!IS_AWS && (BSTG.env === 'dev')) {
    backstageDomain = DEFAULT_APP_ROOT_DOMAIN;
}

/**
 * Main central / single domain backstage URL (as opposed to region specific backstage URL).
 * e.g. https://backstage.spotme.com
 * @constant {String} CENTRAL_BACKSTAGE_URL
 */
export const CENTRAL_BACKSTAGE_URL = `https://${APP_SUBDOMAIN}${backstageDomain}${
    BSTG.env === 'dev' && !IS_AWS && location.port ? `:${location.port}` : ''
}`;

/**
 * Path to a specific package. Interpolation: `{{eventId}}`, `{{pkgName}}`.
 * @constant {String} BACKSTAGE_PACKAGE_DETAILS_PATH
 */
export const BACKSTAGE_PACKAGE_DETAILS_PATH = '/event/{{eventId}}/marketplace/{{pkgName}}';


// Assets

/**
 * The workspace icon name.
 * @constant {Object} WORKSPACE_APP_ICON_NAME
 */
export const WORKSPACE_APP_ICON_NAME = 'uberapp.png';

/**
 * The workspace icon upload payload.
 * @constant {Object} WORKSPACE_APP_ICON_PAYLOAD
 */
export const WORKSPACE_APP_ICON_PAYLOAD = {
    fpType: 'theme_asset',
    fileName: WORKSPACE_APP_ICON_NAME,
    docData: {
        _id: 'theme_asset.app-icon',
        fp_ext_id: 'app-icon',
        asset_name: 'app-icon.png',
        fp_owner: 'private',
        fp_type: 'theme_asset'
    }
};

/**
 * Path to the static assets base folder
 * @constant {String} BASE_ASSETS_PATH
 */
export const BASE_ASSETS_PATH = '/static';

/**
 * Path to the static images base folder
 * @constant {String} IMAGE_PATH
 */
export const IMAGE_PATH = `${BASE_ASSETS_PATH}/img`;

/**
 * API endpoint for downloading an asset
 * @const {string} ASSET_DOWNLOAD_URL
 * @private
 */
export const ASSET_DOWNLOAD_URL = '/api/v1/events/{{eventId}}/assets/{{docId}}/{{fileName}}';

/**
 * The template icon path. Interpolations: `{{templateId}}`
 * @constant {String} TEMPLATE_ICON_PATH
 */
export const TEMPLATE_ICON_PATH = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/backstage/templates/{{templateId}}/icon`;


// Event

/**
 * Path to gather informations about an event. Interpolation: `{{eventId}}`.
 * @constant {String} EVENT_SERVICE_ENDPOINT
 */
export const EVENT_SERVICE_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/events/{{eventId}}`;

/**
 * Path to convert workspace from test to production. Interpolation: `{{eventId}}`.
 * @constant {String} EVENT_CONVERT_TO_PRODUCTION
 */
export const EVENT_CONVERT_TO_PRODUCTION = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/events/{{eventId}}/convert-to-production`;

/**
 * Path to gather informations about an event in local node. Interpolation: `{{eventId}}`.
 * @constant {String} EVENT_SERVICE_LOCAL_ENDPOINT
 */
export const EVENT_SERVICE_LOCAL_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}`;

/**
 * Event's checklist API endpoint. Interpolation: `{{eventId}}`.
 * @constant {String} EVENT_CHECKLIST_ENDPOINT
 */
export const EVENT_CHECKLIST_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/checklist`;

/**
 * The URL of getting start page
 * @constant {String} GETTING_STARTED_URL
 */
export const GETTING_STARTED_URL = 'http://sl.spotme.com/getting-started';

/**
 * Main marketplace route. Interpolation: `{{eventId}}`.
 * @constant {String} MARKETPLACE_MAIN_ROUTE
 */
export const MARKETPLACE_MAIN_ROUTE = '/event/{{eventId}}/marketplace';

/**
 * Main dashboard route
 * @constant {String} DASHBOARD_MAIN_ROUTE
 */
export const EVENT_DASHBOARD_ROUTE = 'event/{{eventId}}';

/**
 * Polling interval for refreshing events that are still in process of being created
 * @constant {Number} EVENT_NOT_READY_POLLING_INTERVAL polling time in ms
 */
export const EVENT_NOT_READY_POLLING_INTERVAL = 5000;


// Servers

/**
 * Server instances list API endpoint
 * @constant {String} SERVERS_SERVICE_ENDPOINT
 */
export const SERVERS_SERVICE_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/servers`;

/**
 * Grouped server instances list API endpoint. Interpolation: `{{orgName}}`.
 * @constant {String} SERVERS_SERVICE_GROUPS_ENDPOINT
 */
export const SERVERS_SERVICE_GROUPS_ENDPOINT = `${SERVERS_SERVICE_ENDPOINT}/groups/{{orgName}}`;

/**
 * Root server instance API endpoint. Interpolation: `{{serverGroup}}`.
 * @constant {String} SERVERS_SERVICE_ROOT_ENDPOINT
 */
export const SERVERS_SERVICE_ROOT_ENDPOINT = `${SERVERS_SERVICE_ENDPOINT}/root/{{serverGroup}}`;


// Marketplace

/**
 * Main marketplace package descriptions path. Interpolation: `{{eventId}}`.
 * @constant {String} MARKETPLACE_MAIN_PATH
 */
export const MARKETPLACE_MAIN_PATH = `${API_BASE_PATH}/events/{{eventId}}/marketplace`;

/**
 * Path to get packages latest available updates. Interpolation: `{{eventId}}`.
 * @constant {String} MARKETPLACE_PACKAGES_UPDATES_PATH
 */
export const MARKETPLACE_PACKAGES_UPDATES_PATH = `${MARKETPLACE_MAIN_PATH}/updates`;

/**
 * Path to package details page. Interpolation: `{{eventId}}`, `{{pkgName}}`.
 * @constant {String} MARKETPLACE_PACKAGE_DETAILS_PATH
 */
export const MARKETPLACE_PACKAGE_DETAILS_PATH = `${MARKETPLACE_MAIN_PATH}/{{pkgName}}`;

/**
 * Path to install package. Interpolation: `{{eventId}}`, `{{pkgName}}`.
 * @constant {String} MARKETPLACE_INSTALL_PACKAGE_PATH
 */
export const MARKETPLACE_INSTALL_PACKAGE_PATH = `${MARKETPLACE_MAIN_PATH}/{{pkgName}}/install`;

/**
 * Path to uninstall package. Interpolation: `{{eventId}}`, `{{pkgName}}`.
 * @constant {String} MARKETPLACE_UNINSTALL_PACKAGE_PATH
 */
export const MARKETPLACE_UNINSTALL_PACKAGE_PATH = `${MARKETPLACE_MAIN_PATH}/{{pkgName}}/uninstall`;

/**
 * Path to update package. Interpolation: `{{eventId}}`, `{{pkgName}}`.
 * @constant {String} MARKETPLACE_UPDATE_PACKAGE_PATH
 */
export const MARKETPLACE_UPDATE_PACKAGE_PATH = `${MARKETPLACE_MAIN_PATH}/{{pkgName}}/update`;

/**
 * Path to update all packages. Interpolation: `{{eventId}}`.
 * @constant {String} MARKETPLACE_UPDATE_ALL_PACKAGE_PATH
 */
export const MARKETPLACE_UPDATE_ALL_PACKAGE_PATH = `${MARKETPLACE_MAIN_PATH}/update_all`;

/**
 * Path to get a package changelog. Interpolation: `{{eventId}}`, `{{pkgName}}`.
 * @constant {String} MARKETPLACE_CHANGELOG_PATH
 */
export const MARKETPLACE_CHANGELOG_PATH = `${MARKETPLACE_MAIN_PATH}/{{pkgName}}/changelog`;

/**
 * Path to get all organization's packages descriptions. Interpolation: `{{orgId}}`.
 * @constant {String} MARKETPLACE_DESCRIPTIONS_PATH
 */
export const MARKETPLACE_DESCRIPTIONS_PATH = `${API_BASE_PATH}/marketplace-description/{{orgId}}`;

/**
 * Path to get a package description. Interpolation: `{{orgId}}`, `{{pkgName}}`, `{{version}}`.
 * @constant {String} MARKETPLACE_DESCRIPTION_PATH
 */
export const MARKETPLACE_DESCRIPTION_PATH = `${MARKETPLACE_DESCRIPTIONS_PATH}/{{pkgName}}/{{version}}`;

/**
 * Path to managing packages images. Interpolation: `{{orgId}}`, `{{pkgName}}`, `{{version}}`, `{{imageId}}`.
 * @constant {String} MARKETPLACE_IMAGE_PATH
 */
export const MARKETPLACE_IMAGE_PATH = `${API_BASE_PATH}/marketplace-image/{{orgId}}/{{pkgName}}/{{version}}/{{imageId}}`;

/**
 * Path to managing packages images. Interpolation: `{{orgId}}`
 * @constant {String} MARKETPLACE_PACKAGES_LIST_PATH
 */
export const MARKETPLACE_PACKAGES_LIST_PATH = `${API_BASE_PATH}/packages/list/{{orgId}}`;

/**
 * Path to get package versions belonging to this organization. Interpolation: `{{eventId}}`
 * @constant {String} MARKETPLACE_INSTALLED_PATH
 */
export const MARKETPLACE_INSTALLED_PATH = `${API_BASE_PATH}/packages/installed/event/{{eventId}}`;

/**
 * Package draft state.
 * @constant {String} MARKETPLACE_DESC_STATE_DRAFT
 */
export const MARKETPLACE_DESC_STATE_DRAFT = 'draft';

/**
 * Package published state.
 * @constant {String} MARKETPLACE_DESC_STATE_PUBLISHED
 */
export const MARKETPLACE_DESC_STATE_PUBLISHED = 'published';

/**
 * Path to gather informations about marketplace job result. Interpolation: `{{jobId}}`.
 * @constant {String} MARKETPLACE_QUEUE_JOB_RESULT_PATH
 */
export const MARKETPLACE_QUEUE_JOB_RESULT_PATH = `${MARKETPLACE_MAIN_PATH}/job/{{jobId}}`;


// Organization

/**
 * Default company name
 * @constant {String} DEFAULT_COMPANY
 */
export const DEFAULT_COMPANY = 'SpotMe';

/**
 * The SpotMe support link
 * @constant {String} SPOTME_SUPPORT_LINK
 */
export const SPOTME_SUPPORT_LINK = 'https://spotme.com/help';

/**
 * Organization IDs prefix.
 * @constant {String} ORGANIZATION_ID_PREFIX
 */
export const ORGANIZATION_ID_PREFIX = 'org.couchdb.user:';

/**
 * SpotMe's organization ID.
 * @constant {String} SPOTME_ORG_ID
 */
export const SPOTME_ORG_ID = `${ORGANIZATION_ID_PREFIX}${DEFAULT_COMPANY}`;

/**
 * Organization API endpoint. Interpolation: `{{orgName}}`.
 * @constant {String} API_ORG_ENDPOINT
 */
export const API_ORG_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/orgs/{{orgName}}`;

/**
 * Organisation default workspace settings endpoint. Interpolation: `{{orgName}}`.
 * @constant {String} WORKSPACE_DEFAULT_SETTINGS_ENPOINT
 */
export const WORKSPACE_DEFAULT_SETTINGS_ENPOINT = `${API_ORG_ENDPOINT}/workspaces-settings`;

/**
 * API endpoint to manipulate an org web hook. Interpolations: `{{orgName}}`
 * @constant {String} WEBHOOK_API_ENDPOINT
 */
export const WEBHOOK_API_ENDPOINT = `${API_BASE_PATH}/orgs/{{orgName}}/webhook`;

/**
 * API endpoint to delete an org web hook. Interpolations: `{{orgName}}`, `{{id}}`
 * @constant {String} WEBHOOK_DELETE_API_ENDPOINT
 */
export const WEBHOOK_DELETE_API_ENDPOINT = `${WEBHOOK_API_ENDPOINT}/{{id}}`;

/**
 * SSO Domain used to login users. Interpolations: `{{domain}}`.
 * @const {String} SSO_DOMAIN_ENDPOINT
 */
export const SSO_DOMAIN_ENDPOINT = `${API_BASE_PATH}/sso/{{domain}}`;

/**
 * Public users registration page URL. Interpolations: `{{orgName}}`, `{{path}}`
 * @const {String} PUBLIC_REGISTRATION_URL
 */
export const PUBLIC_REGISTRATION_URL = BSTG.webappRegPageUrl;


// Long running jobs

/**
 * Path to gather informations about a job status. Interpolation: `{{jobId}}`.
 * @constant {String} QUEUE_JOB_STATUS_PATH
 */
export const QUEUE_JOB_STATUS_PATH = `${API_BASE_PATH}/task-queue/job/{{jobId}}/status`;

/**
 * Path to get a job result. Interpolation: `{{jobId}}`.
 * @constant {String} QUEUE_JOB_RESULT_PATH
 */
export const QUEUE_JOB_RESULT_PATH = `${API_BASE_PATH}/task-queue/job/{{jobId}}/result`;


// User

/**
 * Minimal length of user password.
 * @constant {String} MIN_PASSWORD_LENGTH
 */
export const MIN_PASSWORD_LENGTH = 12;

/**
 * Path for the user manipulation. Interpolation: `{{username}}`, `{{path}}`.
 * It is different from getting the session, has it needs to be done directly on DS.
 * @constant {String} USER_SERVICE_ENDPOINT
 */
export const USER_SERVICE_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/users/{{username}}/{{path}}`;

/**
 * Path for the profile manipulation.
 * @constant {String} USER_PROFILE_ENDPOINT
 */
export const USER_PROFILE_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/users/me`;

/**
 * Path for the user password manipulation.
 * @constant {String} USER_PASSWORD_ENDPOINT
 */
export const USER_PASSWORD_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/users/change-password`;

/**
 * Path for the user session manipulation.
 * @constant {String} USER_SESSION_ENDPOINT
 */
export const USER_SESSION_ENDPOINT = `${API_BASE_PATH}/session`;

/**
 * Path to get user doc. Interpolation: `{{username}}`, `{{noEvents}}`.
 * @constant {String} USER_GET_ENDPOINT
 */
export const USER_GET_ENDPOINT = `${API_BASE_PATH}/users/{{username}}?eid={{eid}}&noEvents={{noEvents}}`;

/**
 * Path to get user doc with all events. Interpolation: `{{username}}`
 * @constant {String} USER_GET_ENDPOINT_ALL_EVENTS
 */
export const USER_GET_ENDPOINT_ALL_EVENTS = `${API_BASE_PATH}/users/{{username}}`;

/**
 * The get all user's sessions path interpolation: `{{orgId}}`.
 * @constant {String} USER_SESSIONS_ALL_PATH
 */
export const USER_SESSIONS_ALL_PATH = `${API_BASE_PATH}/orgs/{{orgId}}/user-sessions`;

/**
 * The cancel all user's sessions path interpolation: `{{orgId}}`.
 * @constant {String} USER_SESSIONS_CANCEL_ALL_PATH
 */
export const USER_SESSIONS_CANCEL_ALL_PATH = `${API_BASE_PATH}/orgs/{{orgId}}/user-sessions/cancel`;

/**
 * The invalidate all user's sessions path interpolation: `{{orgId}}`.
 * @constant {String} USER_SESSIONS_INVALIDATE_ALL_PATH
 */
export const USER_SESSIONS_INVALIDATE_ALL_PATH = `${API_BASE_PATH}/orgs/{{orgId}}/user-sessions/invalidate`;

/**
 * The get user session for event path interpolation: `{{eventId}}`, `{{userId}}`.
 * @constant {String} USER_SESSIONS_GET_IN_EVENT_PATH
 */
export const USER_SESSIONS_GET_IN_EVENT_PATH = `${API_BASE_PATH}/events/{{eventId}}/user-sessions/{{userId}}`;

/**
 * The cancel user in event path interpolation: `{{eventId}}`, `{{userId}}`.
 * @constant {String} USER_SESSIONS_CANCEL_IN_EVENT_PATH
 */
export const USER_SESSIONS_CANCEL_IN_EVENT_PATH = `${API_BASE_PATH}/events/{{eventId}}/user-sessions/{{userId}}/cancel`;

/**
 * The invalidate user in event path interpolation: `{{eventId}}`, `{{userId}}`.
 * @constant {String} USER_SESSIONS_INVALIDATE_IN_EVENT_PATH
 */
export const USER_SESSIONS_INVALIDATE_IN_EVENT_PATH = `${API_BASE_PATH}/events/{{eventId}}/user-sessions/{{userId}}/invalidate`;

/**
 * The user profile path interpolation: `{{eventId}}`, `{{userId}}`.
 * @constant {String} USER_PROFILE_PATH
 */
export const USER_PROFILE_PATH = '/event/{{eventId}}/participants/{{userId}}';

/**
 * List of mandatory clauses users must accept in order to use backstage.
 * @constant {String[]} USER_MANDATORY_CLAUSES
 */
export const USER_MANDATORY_CLAUSES = ['terms_of_service'];

/**
 * Backstage terms of service url.
 * @constant {String} BACKSTAGE_TERMS_OF_SERVICE_LINK
 */
export const BACKSTAGE_TERMS_OF_SERVICE_LINK = 'https://spotme.com/terms';

/**
 * Clauses i18n sorted interpolations.
 * @constant {object} USER_MANDATORY_CLAUSES_I18N_REPLACEMENTS
 */
export const USER_MANDATORY_CLAUSES_I18N_REPLACEMENTS = {
    'terms_of_service': [BACKSTAGE_TERMS_OF_SERVICE_LINK]
};


// Roles

/**
 * Role: Super admin.
 * @constant {String} ROLE_SUPERADMIN
 */
export const ROLE_SUPERADMIN = 'superadmin';

/**
 * Role: Spotme employee
 * @constant {String} ROLE_SPOTME
 */
export const ROLE_SPOTME = 'spotme';

/**
 * Role: Spotme account manager
 * @constant {String} ROLE_SPOTME_AM
 */
export const ROLE_SPOTME_AM = 'spotme_am';

/**
 * Role: Spotme customer success manager
 * @constant {String} ROLE_SPOTME_CSM
 */
export const ROLE_SPOTME_CSM = 'spotme_csm';

/**
 * Role: Spotme finance
 * @constant {String} ROLE_SPOTME_FINANCE
 */
export const ROLE_SPOTME_FINANCE = 'spotme_finance';

/**
 * Role: Spotme Support
 * @constant {String} ROLE_SPOTME_SUPPORT
 */
export const ROLE_SPOTME_SUPPORT = 'spotme_support';

/**
 * Role: Admin.
 * @constant {String} ROLE_ADMIN
 */
export const ROLE_ADMIN = 'admin';

/**
 * Role: Manager.
 * @constant {String} ROLE_MANAGER
 */
export const ROLE_MANAGER = 'manager';

/**
 * Role: Beta tester. Typically assigned to users who should have access to features under development, such as Redesign.
 * @constant {String} ROLE_BETA_TESTER
 */
export const ROLE_BETA_TESTER = 'beta_tester';

/**
 * Role: Developer.
 * @constant {String} ROLE_DEVELOPER
 */
export const ROLE_DEVELOPER = 'developer';

/**
 * Role: App publisher
 * @constant {String} ROLE_APP_PUBLISHER
 */
export const ROLE_APP_PUBLISHER = 'app_publisher';

/**
 * Role: API developer
 * @constant {String} ROLE_API_DEVELOPER
 */
export const ROLE_API_DEVELOPER = 'api_developer';

/**
 * List of all allowed global roles
 * @constant {String[]} GLOBAL_ROLES
 */
export const GLOBAL_ROLES = [
    ROLE_SUPERADMIN,
    ROLE_SPOTME,
    ROLE_SPOTME_AM,
    ROLE_SPOTME_CSM,
    ROLE_SPOTME_FINANCE,
    ROLE_SPOTME_SUPPORT,
    ROLE_DEVELOPER,
    ROLE_APP_PUBLISHER,
    ROLE_BETA_TESTER
];


// UI

/**
 * Menu minified CSS class
 * @constant {String} MENU_STATE_MINIFIED
 */
export const MENU_STATE_MINIFIED = 'minified-menu';


// Storage & Cache

/**
 * The key under which we save backstage values on the local storage.
 * @constant {String} LOCAL_STORAGE_KEY
 */
export const LOCAL_STORAGE_KEY = 'bstg.local-';

/**
 * How long (in milliseconds) we keep things in local storage by default
 * Currently 1 month.
 * @constant {String} LOCAL_STORAGE_CACHE_DURATION
 */
//                                            ms    s    m    h    d
export const LOCAL_STORAGE_CACHE_DURATION = 1000 * 60 * 60 * 24 * 31;

/**
 * The key under which we save the cookie/localstorage
 * @constant {String} LAST_ACCESSED_EVENTS_KEY
 */
export const LAST_ACCESSED_EVENTS_KEY = 'last_accessed_events';

/**
 * How much last access events showed be saved
 * @constant {String} LAST_ACCESSED_EVENTS_SAVED_COUNT
 */
export const LAST_ACCESSED_EVENTS_SAVED_COUNT = 20;

/**
 * Expresses (in seconds) how long will a cookie last by default.
 * @const {Number} DEFAULT_COOKIES_EXPIRATION_PERIOD
 */
export const DEFAULT_COOKIES_EXPIRATION_PERIOD = 60 * 60 * 24 * 31; // 1 month

/**
 * Storage key used for mark critical updates as read.
 * @const {String} CRITICAL_UPDATE_STORAGE_KEY
 */
export const CRITICAL_UPDATE_STORAGE_KEY = 'critical_update';


// App branding

/**
 * Apps service host name
 * @const {String} APP_SERVICE_HOST
 */
export const APP_SERVICE_HOST = publicDomain;

/**
 * Apps service host name
 * @const {String} APP_SERVICE_API_PROTOCOL
 */
export const APP_SERVICE_API_PROTOCOL = 'https';

/**
 * Apps branding information API endpoint
 * @constant {String} APP_BRANDING_SERVICE_ENDPOINT
 */
export const APP_BRANDING_SERVICE_ENDPOINT = `${API_BASE_PATH}/app-brandings`;

/**
 * Apps branding SSO debug API endpoint. Interpolation: `{{branding}}`
 * @constant {String} APP_BRANDING_SSO_DEBUG_ENDPOINT
 */
export const APP_BRANDING_SSO_DEBUG_ENDPOINT = `${API_BASE_PATH}/app-brandings/{{branding}}/sso-debug`;

/**
 * Apps branding SSO client entry point URL. Interpolations: `{{branding}}`, `{{id}}`.
 * @const {String} APP_BRANDING_SSO_ENTRYPOINT
 */
export const APP_BRANDING_SSO_ENTRYPOINT = `${APP_SERVICE_API_PROTOCOL}://${APP_SERVICE_HOST}${API_BASE_PATH}/appservice/sso/branding/{{branding}}/auth/{{id}}`;

/**
 * Apps branding SSO callback URL. Interpolations: `{{branding}}`, `{{id}}`.
 * @const {String} APP_BRANDING_SSO_CALLBACK
 */
export const APP_BRANDING_SSO_CALLBACK = `${APP_SERVICE_API_PROTOCOL}://${APP_SERVICE_HOST}${API_BASE_PATH}/appservice/sso/callback/{{branding}}/{{id}}`;

/**
 * Apps branding SSO SAML metadata API endpoint. Interpolations: `{{branding}}`, `{{id}}`.
 * @const {String} APP_BRANDING_SSO_SAML_METADATA_ENDPOINT
 */
export const APP_BRANDING_SSO_SAML_METADATA_ENDPOINT = `${API_BASE_PATH}/appservice/sso/branding/{{branding}}/auth/{{id}}/saml-sp-metadata`;


/**
 * Apps branding SAML default issuer. Interpolations: `{{branding}}`, `{{id}}`.
 * @const {String} APP_BRANDING_SSO_SAML_METADATA_ENDPOINT
 */
export const APP_BRANDING_SSO_SAML_DEFAULT_ISSUER = `${APP_SERVICE_API_PROTOCOL}://${APP_SERVICE_HOST}${APP_BRANDING_SSO_SAML_METADATA_ENDPOINT}`;

/**
 * Apps branding SSO SAML configuration API endpoint. Interpolations: `{{branding}}`, `{{id}}`.
 * @const {String} APP_BRANDING_SSO_SAML_CONFIG_ENDPOINT
 */
export const APP_BRANDING_SSO_SAML_CONFIG_ENDPOINT = `${API_BASE_PATH}/appservice/sso/branding/{{branding}}/auth/{{id}}/parse-idp-metadata`;

/**
 * Apps branding OpenID SSO configuration discovery API endpoint. Interpolation: `{{branding}}`.
 * @const {String} APP_BRANDING_SSO_OPEN_ID_DISCOVERY_ENDPOINT
 */
export const APP_BRANDING_SSO_OPEN_ID_DISCOVERY_ENDPOINT = `${API_BASE_PATH}/appservice/sso/branding/{{branding}}/auth/{{id}}/openidconnect-discover`;

/**
 * Default branding eventspace
 * @constant {Object} APP_BRANDING_EVENTSPACE
 */
export const APP_BRANDING_EVENTSPACE = {
    _id: 'eventspace',
    fp_ext_id: 'eventspace',
    springboard_name: 'Eventspace by SpotMe'
};

// Open Connect ID

/**
 * The open connect ID main endpoint.
 * @constant {String} OIDC_ENDPOINT
 */
export const OIDC_ENDPOINT = `${CENTRAL_BACKSTAGE_URL}${API_BASE_PATH}/oidc`;

/**
 * The open connect ID grand endpoint. Interpolation: `{{grant}}`.
 * @constant {String} OIDC_GRANT_ENDPOINT
 */
export const OIDC_GRANT_ENDPOINT = `${OIDC_ENDPOINT}/interaction/{{grant}}`;

/**
 * The open connect ID token endpoint.
 * @constant {String} OIDC_TOKEN_ENPOINT
 */
export const OIDC_TOKEN_ENPOINT = `${OIDC_ENDPOINT}/backstage-token`;

/**
 * The open connect ID tokens endpoint.
 * @constant {String} OIDC_TOKENS_ENPOINT
 */
export const OIDC_TOKENS_ENPOINT = `${OIDC_ENDPOINT}/token-list`;

/**
 * The open connect ID revoke token endpoint.
 * @constant {String} OIDC_REVOKE_TOKEN_ENPOINT
 */
export const OIDC_REVOKE_TOKEN_ENPOINT = `${OIDC_ENDPOINT}/backstage-token/revocation`;


// Workspaces

/**
 * API path for creating the workspace creation job.
 * @constant {String} CREATE_WORKSPACE_ENDPOINT
 */
export const CREATE_WORKSPACE_ENDPOINT = `${API_BASE_PATH}/event-creation-jobs`;

/**
 * API path for getting a CSRF token
 * @constant {String} CSRF_TOKEN_ENDPOINT
 */
export const CSRF_TOKEN_ENDPOINT = `${API_BASE_PATH}/app-brandings/csrf-token`;

/**
 * API path for uploading workspace image icon
 * @constant {String} EVENT_ASSETS_UPLOAD_ENDPOINT
 */
export const EVENT_ASSETS_UPLOAD_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/appscripts/assets/upload`;

/**
 * Path to gather informations about workspace creation job result. Interpolations: `{{jobId}}`.
 * @constant {String} CREATE_WORKSPACE_QUEUE_JOB_RESULT_PATH
 */
export const CREATE_WORKSPACE_QUEUE_JOB_RESULT_PATH = `${API_BASE_PATH}/event-instance-creation-jobs/{{jobId}}`;

/**
 * API path for getting a CSRF token
 * @constant {String} CSRF_TOKEN_ENDPOINT_ROUTER
 */
export const CSRF_TOKEN_ENDPOINT_ROUTER = `${API_BASE_PATH}/app-brandings/csrf-token`;

/**
 * Path to get the webapp login url for this user. Interpolation: `{{eventId}}`
 * @constant {String} WEP_APP_LOGIN_URL_ENDPOINT
 */
export const WEP_APP_LOGIN_URL_ENDPOINT = `${API_BASE_PATH}/event/{{eventId}}/backstage/webapp-login-url`;

// Webinars

/**
 * Path of a webinar's icon
 *
 * @constant {String} WEBINAR_ICON_ENDPOINT
 */
export const WEBINAR_ICON_ENDPOINT = `${API_BASE_PATH}/webinar/{{eventId}}/icon`;

/**
 * Path of a webinar's banner
 *
 * @constant {String} WEBINAR_BANNER_ENDPOINT
 */
export const WEBINAR_BANNER_ENDPOINT = `${API_BASE_PATH}/webinar/{{eventId}}/banner`;


// Legal documents

/**
 * Organization legal documents endpoin api path. Interpolations: `{{baseUrl}}`, `{{orgName}}`.
 * @constant {String} LEGAL_DOCUMENTS_FOR_ORG_ENDPOINT
 */
export const LEGAL_DOCUMENTS_FOR_ORG_ENDPOINT = `{{baseUrl}}${API_BASE_PATH}/orgs/{{orgName}}/legal-documents`;

/**
 * Legal templates list API endpoint. Interpolation: `{{baseUrl}}`.
 * @constant {String} LEGAL_DOCUMENTS_TEMPLATES_ENDPOINT
 */
export const LEGAL_DOCUMENTS_TEMPLATES_ENDPOINT = `{{baseUrl}}${API_BASE_PATH}/legal-templates`;

/**
 * Legal template API endpoint. Interpolations: `{{baseUrl}}`, `{{id}}`.
 * @constant {String} LEGAL_DOCUMENTS_TEMPLATE_ENDPOINT
 */
export const LEGAL_DOCUMENTS_TEMPLATE_ENDPOINT = `{{baseUrl}}${API_BASE_PATH}/legal-templates/{{id}}`;

/**
 * Legal document API endpoint. Interpolations: `{{baseUrl}}`, `{{id}}`.
 * @constant {String} LEGAL_DOCUMENT_ENDPOINT
 */
export const LEGAL_DOCUMENT_ENDPOINT = `{{baseUrl}}${API_BASE_PATH}/legal-documents/{{id}}`;

/**
 * The legal document url. Interpolation: `{{id}}`.
 * @constant {String} LEGAL_DOCUMENT_URL
 */
export const LEGAL_DOCUMENT_URL = `{{baseUrl}}${API_BASE_PATH}/legal/document/{{id}}.html`;

/**
 * The organization legal document manager
 * @constant {String} LEGAL_DOCUMENT_MANAGER_PATH
 */
export const LEGAL_DOCUMENT_MANAGER_PATH = '/org/{{orgName}}/legal/';


// Dates and time

/**
 * The default system date format
 * @constant {String} DEFAULT_DATE_FORMAT
 */
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * The default system date-time format
 * @constant {String} DEFAULT_DATETIME_FORMAT
 */
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';

/**
 * The default system time format
 * @constant {String} DEFAULT_TIME_FORMAT
 */
export const DEFAULT_TIME_FORMAT = 'HH:mm';


// Knowledge base

/**
 * The knowledge base article url for webhooks
 * @constant {String} KB_SPOTME_WEBHOOKS
 */
export const KB_SPOTME_WEBHOOKS = 'http://sl.spotme.com/webhooks';

/**
 * The knowledge base article url for studio permissions
 * @constant {String} KB_STUDIO_PERMISSIONS
 */
export const KB_STUDIO_PERMISSIONS = 'http://sl.spotme.com/studio-permissions';

/**
 * The knowledge base article url for screenshare on studio
 * @constant {String} KB_STUDIO_SCREENSHARE
 */
export const KB_STUDIO_SCREENSHARE = 'http://sl.spotme.com/how-to-screenshare-mac';

/**
 * The knowledge base article url for fp type limits
 * @constant {String} KB_FP_TYPE_LIMITS
 */
export const KB_FP_TYPE_LIMITS = 'https://support.spotme.com/hc/en-us/articles/4408591514131';

/**
 * The kb article url for the list of free email domains
 * @constant {string} KB_FREE_EMAIL_DOMAINS_LIST
 */
export const KB_FREE_EMAIL_DOMAINS_LIST = 'https://sl.spotme.com/blocked-email-domains';

/**
 * Blacklisted modules
 * These are the modules of packages that we merged back to BSTG
 * Files from these modules won't be loaded
 * @constant {String[]} MODULES_BLACKLIST
 */
export const MODULES_BLACKLIST = ['modal-help', 'metadata_filter', 'metadata_selector', 'theme-editor', 'files'];

/**
 * Blacklisted scripts that should not be imported anymore
 * as they were merged to backstage
 * @constant {String[]} SCRIPT_IMPORT_BLACKLIST
 */
export const SCRIPT_IMPORT_BLACKLIST = [
    // bstg-tools
    'bstg-source/bt-apply-metadata-defaults.js',
    'bstg-source/bt-deeplink-token-custom-kind-controller.js',
    'bstg-source/bt-dnd.js',
    'bstg-source/bt-generate-unique-id.js',
    'bstg-source/bt-highchart-directive.js',
    'bstg-source/bt-local-storage-sync.js',
    'bstg-source/bt-metadata-filter-controller.js',
    'bstg-source/bt-metadata-selector-controller.js',
    'bstg-source/bt-modal-directive.js',
    'bstg-source/bt-permalink-launcher-directive.js',
    'bstg-source/bt-save-bit-patch.js',
    'bstg-source/bt-system-loader.js',
    'bstg-source/bt-system.js',
    'bstg-source/bt-url.js',
    // theme-editor
    'bstg-source/colorThiefService.js',
    'bstg-source/themeAssetsCtrl.js',
    'bstg-source/themeEditorCtrl.js',
    'bstg-source/themeImporterCtrl.js',
    'bstg-source/themeImporterService.js',
    'bstg-source/themePreview.js',
    'bstg-source/themeService.js',
    'bstg-source/themeWizardCtrl.js',
    'bstg-source/tinyColorService.js',
];

/**
 * Blacklisted blueprint source urls
 * @constant {String[]} SOURCE_URL_BLACKLIST
 */
export const SOURCE_URL_BLACKLIST = SCRIPT_IMPORT_BLACKLIST.map(script => `/api/v1/${script}`);

/**
 * Mapping for templates that were merged to backstage
 * @constant {Object<String, String>} TEMPLATE_URL_MAPPING
 */
export const TEMPLATE_URL_MAPPING = {
    // bstg-tools
    '/api/v1/bstg-template/bt-metadata-filter.html': '/static/partials/tools/bt-metadata-filter.html',
    '/api/v1/bstg-template/bt-metadata-selector.html': '/static/partials/tools/bt-metadata-selector.html',
    '/api/v1/bstg-template/bt-modal.html': '/static/partials/tools/bt-modal.html',
    '/api/v1/bstg-template/bt-system-loader.html': '/static/partials/tools/bt-system-loader.html',
    // theme-editor
    '/api/v1/bstg-template/_colours.html': '/static/partials/theme-editor/_colours.html',
    '/api/v1/bstg-template/_home.html': '/static/partials/theme-editor/_home.html',
    '/api/v1/bstg-template/_spotman.html': '/static/partials/theme-editor/_spotman.html',
    '/api/v1/bstg-template/theme-assets-graphics.html': '/static/partials/theme-editor/theme-assets-graphics.html',
    '/api/v1/bstg-template/theme-editor-importer.html': '/static/partials/theme-editor/theme-editor-importer.html',
    '/api/v1/bstg-template/theme-editor-mini-preview.html': '/static/partials/theme-editor/theme-editor-mini-preview.html',
    '/api/v1/bstg-template/theme-editor.html': '/static/partials/theme-editor/theme-editor.html',
    '/api/v1/bstg-template/theme-wizard.html': '/static/partials/theme-editor/theme-wizard.html'
};

/**
 * Mapping for controller names that were merged to backstage
 * @constant {Object<String, String>} CONTROLLER_NAME_MAPPING
 */
export const CONTROLLER_NAME_MAPPING = {
    // theme-editor
    'c_ThemeEditorCtrl': 'ThemeEditorCtrl',
    'c_ThemeAssetsCtrl': 'ThemeAssetsCtrl',
    'c_ThemeWizardCtrl': 'ThemeWizardCtrl',
};

/**
 * The default local language, to use in localized cases.
 * @constant {String} DEFAULT_LANGUAGE
 */
export const DEFAULT_LANGUAGE = 'en';

/**
 * Add to event team API endpoint. Interpolations: `{{email}}`.
 * @constant {String} EVENT_ADD_TO_TEAM_ENDPOINT
 */
export const EVENT_ADD_TO_TEAM_ENDPOINT = `${API_BASE_PATH}/users/{{email}}/add-to-team`;

/**
 * Live stream list route. Interpolation: `{{eventId}}`.
 * @constant {String} LIVE_STREAM_LIST_PATH
 */
export const LIVE_STREAM_LIST_PATH = '/event/{{eventId}}/live_streams';

/**
 * Link to learn more about manual approval of users
 * @constant {String} KB_MORE_ABOUT_MANUAL_USER_APPROVAL_URL
 */
export const KB_MORE_ABOUT_MANUAL_USER_APPROVAL_URL = 'https://sl.spotme.com/how-to-reg-approvals';

// Signals

/**
 * The channel used to send checklist signals.
 * @constant {String} CHECKLIST_CHANNEL
 */
export const CHECKLIST_CHANNEL = 'bstg-skeleton/checklist';

/**
 * The channel used to send live stream update signals. Interpolations: `{{liveStreamId}}`
 * @constant {String} LIVE_STREAM_STATE_UPDATE
 */
export const LIVE_STREAM_STATE_UPDATE = 'liveStream/{{liveStreamId}}/stream';

/**
 * The channel used to send input-switch update signals. Interpolations: `{{liveStreamId}}`
 * @constant {String} INPUT_SWITCH_DOC_UPDATE_SIGNAL
 */
export const INPUT_SWITCH_DOC_UPDATE_SIGNAL = 'liveStream/{{liveStreamId}}/inputSwitch';

/**
 * The channel used to send live stream interactions. Interpolations: `{{liveStreamId}}`
 * @constant {String} LIVE_STREAM_INTERACTIONS
 */
export const LIVE_STREAM_INTERACTIONS = 'liveStream/{{liveStreamId}}/bstg-interactions';

/**
 * The channel used to send live stream questions. Interpolations: `{{liveStreamId}}`
 * @constant {String} LIVE_STREAM_QUESTIONS
 */
export const LIVE_STREAM_QUESTIONS = 'liveStream/{{liveStreamId}}/bstg-questions';

/**
 * The channel used to send live stream notification signals. Interpolations: `{{liveStreamId}}`
 * @constant {String} LIVE_STREAM_NOTIFICATIONS
 */
export const LIVE_STREAM_NOTIFICATIONS = 'liveStream/{{liveStreamId}}/bstg-notifications';

/**
 * The channel used to send live display document update signals. Interpolations: `{{liveDisplayId}}`
 * @constant {String} LIVE_DISPLAY_DOC_UPDATE
 */
export const LIVE_DISPLAY_DOC_UPDATE = 'document/live_display/{{liveDisplayId}}/update';


// Participants

/**
 * Activation codes endpoint. Interpolations: `{{eventId}}`
 * @constant {String} ACT_DOC_ENDPOINT
 */
export const ACT_DOC_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/actdocs`;

/**
 * The user is suspended route
 * @constant {String} SUSPENDED_ROUTE
 */
export const SUSPENDED_ROUTE = '/users/suspended';

/**
 * Url for empty templates screen, the learn more button link
 * @constant {String} KB_TEMPLATE_LEARN_MORE
 */
export const KB_TEMPLATE_LEARN_MORE = 'http://sl.spotme.com/cms-template';


// Routes

/**
 * Organization API documentation route
 * @constant {String} ROUTE_LIMITATIONS_API_DOC
 */
export const ROUTE_LIMITATIONS_API_DOC = '/limitations';

/**
 * Getting started API documentation route
 * @constant {String} ROUTE_GETTING_STARTED_API_DOC
 */
export const ROUTE_GETTING_STARTED_API_DOC = '/getting-started';

/**
 * Direct Data API documentation route
 * @constant {String} ROUTE_DIRECT_DATA_API_DOC
 */
export const ROUTE_DIRECT_DATA_API_DOC = '/direct-data';

/**
 * Direct Data Files API documentation route
 * @constant {String} ROUTE_DIRECT_FILES_DATA_API_DOC
 */
export const ROUTE_DIRECT_DATA_FILES_API_DOC = '/direct-data-files';

/**
 * Organization API documentation route
 * @constant {String} ROUTE_ORG_API_DOC
 */
export const ROUTE_ORG_API_DOC = '/org/:orgName?';

/**
 * Event API documentation route
 * @constant {String} ROUTE_EVENT_API_DOC
 */
export const ROUTE_EVENT_API_DOC = '/workspace/:eid?';

/**
 * Package API documentation route
 * @constant {String} ROUTE_PACKAGE_API_DOC
 */
export const ROUTE_PACKAGE_API_DOC = '/package/:pkgName?/:version?';

/**
 * User token route
 * @constant {String} ROUTE_USERS
 */
export const ROUTE_USERS = '/users';

/**
 * Login route
 * @constant {String} ROUTE_LOGIN
 */
export const ROUTE_LOGIN = '/login';


/**
 * MFA validation login route
 * @constant {String} ROUTE_LOGIN_WITH_MFA
 */
export const ROUTE_LOGIN_WITH_MFA = `${ROUTE_LOGIN}/mfa`;

/**
 * MFA phone capture route
 * @constant {String} ROUTE_MFA_PHONE
 */
export const ROUTE_MFA_PHONE = `${ROUTE_LOGIN}/mfa/phone`;

/**
 * Single Sign On login route
 * @constant {String} ROUTE_LOGIN_WITH_SSO
 */
export const ROUTE_LOGIN_WITH_SSO = `${ROUTE_LOGIN}/sso`;

/**
 * Single Sign On route for direct access
 * @constant {String} ROUTE_SSO_DIRECT_ACCESS
 */
export const ROUTE_SSO_DIRECT_ACCESS = `${ROUTE_LOGIN}/sso/:domain`;

/**
 * Send reset token route
 * @constant {String} ROUTE_SEND_RESET_TOKEN
 */
export const ROUTE_SEND_RESET_TOKEN = `${ROUTE_LOGIN}/password`;

/**
 * Validate account route
 * @constant {String} ROUTE_VALIDATE_ACCOUNT
 */
export const ROUTE_VALIDATE_ACCOUNT = `${ROUTE_LOGIN}/validate-account`;

/**
 * Reset password route
 * @constant {String} ROUTE_RESET_PASSWORD
 */
export const ROUTE_RESET_PASSWORD = `${ROUTE_LOGIN}/password/new`;

/**
 * Reset password MFA route
 * @constant {String} ROUTE_RESET_PASSWORD_MFA
 */
export const ROUTE_RESET_PASSWORD_MFA = `${ROUTE_LOGIN}/password/new/mfa`;

/**
 * Route to the account activation
 * @constant {String} ROUTE_CONFIRM_ACCOUNT
 */
export const ROUTE_CONFIRM_ACCOUNT = `${ROUTE_LOGIN}/create`;

/**
 * Route to webinar template editing page
 * @constant {String} ROUTE_WEBINAR_TEMPLATE
 */
export const ROUTE_WEBINAR_TEMPLATE = '/webinars/templates/:templateId';

/**
 * Route to webinar template editing page
 * @constant {String} ROUTE_WEBINAR_TEMPLATE_LIST
 */
export const ROUTE_WEBINAR_TEMPLATE_LIST = '/webinars/templates';

// Studio

/**
 * @constant {Number} STUDIO_VIDEO_WIDTH the desired video width resolution for studio camera
 */
export const STUDIO_VIDEO_WIDTH = 1280;

/**
 * @constant {Number} STUDIO_VIDEO_HEIGHT the desired video height resolution for studio camera
 */
export const STUDIO_VIDEO_HEIGHT = 720;

/**
 * @const {Number} STUDIO_MAX_SCREEN_SHARE_SPEAKERS Maximum allowed number of speakers in screen share layout
 */
export const STUDIO_MAX_SCREEN_SHARE_SPEAKERS = 6;

/**
 * @const {Number} STUDIO_MAX_LIVE_SPEAKERS Maximum allowed number of live speakers at the same time
 */
export const STUDIO_MAX_LIVE_SPEAKERS = 10;

/**
 * @const {Number} STUDIO_MAX_SCREENS Maximum number of screen shares allowed
 */
export const STUDIO_MAX_SCREENS = 2;

/**
 * @const {Record<String, Number>} STUDIO_QUALITY_MIN_BITRATE_BPS Minimum bitrate for a good enough connectivity, per resolution (total number of pixels)
 */
export const STUDIO_QUALITY_MIN_BITRATE_BPS = {
    [STUDIO_VIDEO_WIDTH * STUDIO_VIDEO_HEIGHT]: 300 * 1000,
    [640 * 360]: 100 * 1000,
    [320 * 180]: 50 * 1000,
};

/**
 * @const {Number} STUDIO_QUALITY_MAX_PACKET_LOSS_PERCENT Maximum packet loss allowed for a good enough connectivity
 */
export const STUDIO_QUALITY_MAX_PACKET_LOSS_PERCENT = 5;

/**
 * @const {Number} STUDIO_MAX_NAME_CHARACTERS Maximum number of user name characters that doesn't get visually truncated
 */
export const STUDIO_MAX_NAME_CHARACTERS = 21;

/**
 * @const {String} EMAIL_MODAL_EVENT name of the event used for the spotme modal
 */
export const EMAIL_MODAL_EVENT = 'spotme-email-modal';

/**
 * @const {Object} ATTENDANCE_TRACKING_DEFAULTS default values for attendance tracking
 */
export const ATTENDANCE_TRACKING_DEFAULTS = {
    in_person_event_activated: false,
    in_person_event_attended_min_sessions: 1,
    virtual_event_activated: false,
    virtual_event_attended_min_sessions: 1,
    virtual_session_watchtime_in_minutes: 5,
    virtual_session_watchtime_in_percent: 5,
    virtual_session_count_vod: false
};

export const SESSION_CHECK_IN_METADATA_FIELDS = [
    'metadata_to_search_by',
    'metadata_to_display',
    'metadata_to_display_checked_in_attendees',
    'metadata_to_display_non_checked_in_attendees',
    'metadata_verification_screen',
];

export const KB_LINK_RECOMMENDED_SETTINGS = 'https://support.spotme.com/hc/en-us/articles/4413459400723-SpotMe-platform-user-technical-requirements#h_01FT8RRDNCD9EB7BKXNR2MGS4W';
export const KB_LINK_FIREWALL_SETTINGS = 'https://support.spotme.com/hc/en-us/articles/360051642033-SpotMe-platform-corporate-firewall-network-requirements';
